import { db, auth } from '../config/firebase';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  orderBy,
  updateDoc,
  deleteDoc,
  Timestamp,
  limit
} from 'firebase/firestore';

// Types
export interface App {
  id: string;
  name: string;
  description: string;
  category: string;
  price: number;
  userId: string;
  userEmail: string;
  companyName: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  updatedAt: {
    seconds: number;
    nanoseconds: number;
  };
  screenshots?: string[];
  demoUrl?: string;
  tags: string[];
}

export interface User {
  id: string;
  email: string;
  companyName: string;
  role: 'admin' | 'business';
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  appCount?: number;
}

export interface Notification {
  id: string;
  type: 'new_app' | 'status_change' | 'system';
  message: string;
  appId?: string;
  appName?: string;
  userId: string;
  userEmail: string;
  read: boolean;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
}

// Apps Collection
export const appsCollection = collection(db, 'apps');

export const createApp = async (appData: Omit<App, 'id' | 'createdAt' | 'updatedAt' | 'status'>) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be authenticated');

  const newApp = {
    ...appData,
    status: 'pending',
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    userId: user.uid,
    userEmail: user.email,
  };

  const docRef = doc(appsCollection);
  await setDoc(docRef, newApp);

  // Create notification for admins
  await createNotification({
    type: 'new_app',
    message: `New app submission: ${appData.name} by ${appData.companyName}`,
    appId: docRef.id,
    appName: appData.name,
    userId: user.uid,
    userEmail: user.email || '',
    read: false,
  });

  return { id: docRef.id, ...newApp };
};

export const updateAppStatus = async (appId: string, status: 'approved' | 'rejected') => {
  const appRef = doc(db, 'apps', appId);
  const appSnap = await getDoc(appRef);
  
  if (!appSnap.exists()) throw new Error('App not found');
  
  const appData = appSnap.data() as App;
  await updateDoc(appRef, {
    status,
    updatedAt: Timestamp.now(),
  });

  // Create notification for app owner
  await createNotification({
    type: 'status_change',
    message: `Your app ${appData.name} has been ${status}`,
    appId,
    appName: appData.name,
    userId: appData.userId,
    userEmail: appData.userEmail,
    read: false,
  });
};

// Users Collection
export const usersCollection = collection(db, 'users');

export const createUser = async (userData: Omit<User, 'id' | 'createdAt'>) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User must be authenticated');

  const newUser = {
    ...userData,
    createdAt: Timestamp.now(),
  };

  await setDoc(doc(usersCollection, user.uid), newUser);
  return { id: user.uid, ...newUser };
};

export const getUserRole = async (userId: string): Promise<'admin' | 'business' | null> => {
  const userDoc = await getDoc(doc(usersCollection, userId));
  return userDoc.exists() ? (userDoc.data() as User).role : null;
};

// Notifications Collection
export const notificationsCollection = collection(db, 'notifications');

export const createNotification = async (notificationData: Omit<Notification, 'id' | 'createdAt'>) => {
  const newNotification = {
    ...notificationData,
    createdAt: Timestamp.now(),
  };

  const docRef = doc(notificationsCollection);
  await setDoc(docRef, newNotification);
  return { id: docRef.id, ...newNotification };
};

export const markNotificationAsRead = async (notificationId: string) => {
  const notificationRef = doc(db, 'notifications', notificationId);
  await updateDoc(notificationRef, { read: true });
};

// Queries
export const getApprovedApps = async () => {
  const q = query(
    appsCollection,
    where('status', '==', 'approved'),
    orderBy('createdAt', 'desc'),
    limit(50)
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as App[];
};

export const getUserApps = async (userId: string) => {
  const q = query(
    appsCollection,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as App[];
};

export const getUnreadNotifications = async (userId: string) => {
  const q = query(
    notificationsCollection,
    where('userId', '==', userId),
    where('read', '==', false),
    orderBy('createdAt', 'desc'),
    limit(50)
  );
  
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as Notification[];
};

export const getAllUsers = async () => {
  const q = query(usersCollection, orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as User[];
};
