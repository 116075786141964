import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button,
  CardActions,
  Box,
  CircularProgress,
  Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

interface App {
  id: string;
  name: string;
  description: string;
  category: string;
  price: string;
  website: string;
  features: string;
}

const Home = () => {
  const navigate = useNavigate();
  const [apps, setApps] = useState<App[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        // Only fetch approved apps
        const appsQuery = query(
          collection(db, 'apps'),
          where('status', '==', 'approved')
        );
        
        const querySnapshot = await getDocs(appsQuery);
        const appsData: App[] = [];
        querySnapshot.forEach((doc) => {
          appsData.push({ id: doc.id, ...doc.data() } as App);
        });
        setApps(appsData);
      } catch (error) {
        console.error('Error fetching apps:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApps();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Welcome to Business App Marketplace
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom align="center" color="textSecondary" sx={{ mb: 6 }}>
        Discover and showcase business applications
      </Typography>

      <Grid container spacing={4} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                For Businesses
              </Typography>
              <Typography variant="body1" paragraph>
                List your applications and reach potential customers. Showcase your innovative solutions to businesses worldwide.
              </Typography>
              <Button variant="contained" color="primary" onClick={() => navigate('/register')}>
                Start Listing
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                For Customers
              </Typography>
              <Typography variant="body1" paragraph>
                Find the perfect business applications to enhance your operations. Browse through our curated collection.
              </Typography>
              <Button variant="contained" color="secondary" onClick={() => navigate('/login')}>
                Explore Apps
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h4" component="h2" gutterBottom sx={{ mt: 6, mb: 4 }}>
        Featured Applications
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {apps.map((app) => (
            <Grid item xs={12} sm={6} md={4} key={app.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {app.name}
                  </Typography>
                  <Chip 
                    label={app.category} 
                    size="small" 
                    sx={{ mb: 2 }} 
                  />
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {app.description}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    Price: {app.price}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    color="primary"
                    href={app.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
          {apps.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="body1" align="center" color="textSecondary">
                No approved applications available yet. Check back soon!
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default Home;
