import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';

export const useAuth = () => {
  const [user, loading, error] = useAuthState(auth);
  const [authError, setAuthError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      console.error('Auth error:', error);
      if (error.message.includes('configuration-not-found')) {
        setAuthError('Firebase authentication is not properly configured. Please check your Firebase setup.');
      } else {
        setAuthError(error.message);
      }
    } else {
      setAuthError(null);
    }
  }, [error]);

  return {
    user,
    loading,
    error: authError,
    isAuthenticated: !!user,
  };
};
