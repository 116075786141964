import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { useAdmin } from '../hooks/useAdmin';

const Navbar = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { isAdmin } = useAdmin();

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => navigate('/')}>
          Business App Marketplace
        </Typography>
        <Box>
          {user ? (
            <>
              {isAdmin && (
                <Button color="inherit" onClick={() => navigate('/admin')}>Admin Panel</Button>
              )}
              <Button color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button>
              <Button color="inherit" onClick={() => navigate('/submit-app')}>Submit App</Button>
              <Button color="inherit" onClick={handleLogout}>Logout</Button>
            </>
          ) : (
            <>
              <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
              <Button color="inherit" onClick={() => navigate('/register')}>Register</Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
