import React, { useEffect, useState, useMemo, ChangeEvent } from 'react';
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  TablePagination,
  Menu,
  MenuItem,
  Badge,
  IconButton,
  Tooltip,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import {
  App,
  User,
  Notification,
  getAllUsers,
  updateAppStatus,
  markNotificationAsRead,
  getUnreadNotifications,
} from '../services/database';
import { db } from '../config/firebase';
import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot,
  DocumentData,
  QuerySnapshot 
} from 'firebase/firestore';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AdminPanel = () => {
  const [tabValue, setTabValue] = useState(0);
  const [apps, setApps] = useState<App[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleNotificationMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationSelect = async (notification: Notification) => {
    if (!notification.read) {
      await markNotificationAsRead(notification.id);
    }
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const setupSubscriptions = async () => {
      setLoading(true);
      try {
        // Set up real-time apps listener
        const unsubscribeApps = onSnapshot(
          query(collection(db, 'apps'), orderBy('createdAt', 'desc')),
          (snapshot: QuerySnapshot<DocumentData>) => {
            const appsData = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            })) as App[];
            setApps(appsData);
          }
        );

        // Fetch initial users data
        const usersData = await getAllUsers();
        setUsers(usersData);

        // Get unread notifications for admins
        const notificationsData = await getUnreadNotifications('admin');
        setNotifications(notificationsData);

        // Cleanup function
        return () => {
          unsubscribeApps();
        };
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = setupSubscriptions();
    return () => {
      unsubscribe.then(cleanup => cleanup && cleanup());
    };
  }, []);

  const handleAppStatusChange = async (appId: string, newStatus: 'approved' | 'rejected') => {
    try {
      await updateAppStatus(appId, newStatus);
      // The real-time listeners will automatically update the UI
    } catch (error) {
      console.error('Error updating app status:', error);
    }
  };

  const filteredUsers = useMemo(() => {
    const searchLower = searchTerm.toLowerCase();
    return users.filter((user: User) => {
      return (
        user.email.toLowerCase().includes(searchLower) ||
        user.companyName.toLowerCase().includes(searchLower) ||
        user.role.toLowerCase().includes(searchLower)
      );
    });
  }, [users, searchTerm]);

  const paginatedUsers = useMemo(() => {
    return filteredUsers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [filteredUsers, page, rowsPerPage]);

  const unreadCount = notifications.filter(n => !n.read).length;

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" component="h1">
          Admin Panel
        </Typography>
        <IconButton onClick={handleNotificationMenuClick}>
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleNotificationClose}
          PaperProps={{
            style: {
              maxHeight: 300,
              width: 360,
            },
          }}
        >
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => handleNotificationSelect(notification)}
                sx={{
                  backgroundColor: notification.read ? 'inherit' : 'action.hover',
                  display: 'block',
                }}
              >
                <Typography variant="body2">
                  {notification.message}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {notification.createdAt ? new Date(notification.createdAt.seconds * 1000).toLocaleString() : 'N/A'}
                </Typography>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No notifications</MenuItem>
          )}
        </Menu>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Apps" />
          <Tab label={`Users (${users.length})`} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>App Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Submitted By</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apps.map((app) => (
                <TableRow key={app.id}>
                  <TableCell>{app.name}</TableCell>
                  <TableCell>{app.description}</TableCell>
                  <TableCell>{app.category}</TableCell>
                  <TableCell>${app.price}</TableCell>
                  <TableCell>
                    <Tooltip title={app.companyName}>
                      <span>{app.userEmail}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={app.status}
                      color={
                        app.status === 'approved'
                          ? 'success'
                          : app.status === 'rejected'
                          ? 'error'
                          : 'warning'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {app.status === 'pending' && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleAppStatusChange(app.id, 'approved')}
                          sx={{ mr: 1 }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => handleAppStatusChange(app.id, 'rejected')}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    {app.status === 'approved' && (
                      <Button
                        variant="outlined"
                        color="warning"
                        size="small"
                        onClick={() => handleAppStatusChange(app.id, 'rejected')}
                      >
                        Revoke
                      </Button>
                    )}
                    {app.status === 'rejected' && (
                      <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        onClick={() => handleAppStatusChange(app.id, 'approved')}
                      >
                        Approve
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Box mb={3}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search users by email, company, or role..."
            value={searchTerm}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Apps</TableCell>
                <TableCell>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user: User) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap={1}>
                      <BusinessIcon color="action" />
                      <Typography>{user.companyName}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={user.role === 'admin' ? 'Administrator' : 'Business User'}>
                      <Chip
                        icon={user.role === 'admin' ? <AdminPanelSettingsIcon /> : <BusinessIcon />}
                        label={user.role}
                        color={user.role === 'admin' ? 'secondary' : 'default'}
                        size="small"
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={user.appCount || 0}
                      size="small"
                      color={user.appCount ? 'primary' : 'default'}
                    />
                  </TableCell>
                  <TableCell>
                    {user.createdAt ? new Date(user.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </TabPanel>
    </Container>
  );
};

export default AdminPanel;
