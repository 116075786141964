// Initialize Firebase app and services
import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBI4DLQHEVIhH_fSNs39cHsMJKBae23D28",
  authDomain: "app-marketplace-8e562.firebaseapp.com",
  projectId: "app-marketplace-8e562",
  storageBucket: "app-marketplace-8e562.appspot.com",
  messagingSenderId: "764221541118",
  appId: "1:764221541118:web:3a06afe1158d64ccfbf3af",
  measurementId: "G-KQXDF2D7V2"
};

// Initialize Firebase app first
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
const analytics = getAnalytics(app);

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.log('Persistence failed: Multiple tabs open');
  } else if (err.code === 'unimplemented') {
    console.log('Persistence not supported by browser');
  }
});

// Export initialized services
export { app, auth, db, analytics };
