import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button, 
  Box,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../config/firebase';
import { useNavigate } from 'react-router-dom';

interface App {
  id: string;
  name: string;
  description: string;
  category: string;
  price: string;
  status: string;
  website: string;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'approved':
      return 'success';
    case 'pending':
      return 'warning';
    case 'rejected':
      return 'error';
    default:
      return 'default';
  }
};

const Dashboard = () => {
  const [user] = useAuthState(auth);
  const [apps, setApps] = useState<App[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchApps = async () => {
      try {
        const appsQuery = query(
          collection(db, 'apps'),
          where('userId', '==', user.uid)
        );
        
        const querySnapshot = await getDocs(appsQuery);
        const appsData: App[] = [];
        
        querySnapshot.forEach((doc) => {
          appsData.push({ id: doc.id, ...doc.data() } as App);
        });
        
        setApps(appsData);
      } catch (error) {
        console.error('Error fetching apps:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchApps();
  }, [user, navigate]);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" component="h1">
          My Applications
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/submit-app')}
        >
          Add New App
        </Button>
      </Box>

      {apps.length === 0 ? (
        <Alert severity="info" sx={{ mt: 2 }}>
          You haven't listed any applications yet. Click "Add New App" to get started!
        </Alert>
      ) : (
        <Grid container spacing={3}>
          {apps.map((app) => (
            <Grid item xs={12} md={6} lg={4} key={app.id}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <Typography variant="h6" gutterBottom>
                      {app.name}
                    </Typography>
                    <Chip
                      label={app.status}
                      color={getStatusColor(app.status) as any}
                      size="small"
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {app.description}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Category: {app.category}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Price: {app.price}
                  </Typography>
                  {app.status === 'pending' && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                      Your app is pending approval from our administrators.
                    </Alert>
                  )}
                  {app.status === 'rejected' && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      Your app submission was not approved. Please contact support for more information.
                    </Alert>
                  )}
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      href={app.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Website
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Dashboard;
